import httpClient from './httpClient';

const END_POINT = '/skills';
const mapAuthHeader = (token, opts = {}) => ({
  headers: {
    Authorization: `Bearer ${token}`,
    ...opts,
  },
});

const listSkills = (token) => httpClient.get(`${END_POINT}`, {
  ...mapAuthHeader(token),
});

export default listSkills;

export {
  listSkills,
};
