<template>
  <div id="app" class="site" :lang="$i18n.locale" data-server-rendered="true">
    <router-view/>
  </div>
</template>

<style lang="scss" src="./sass/style.scss"></style>

<script>
export default {
  name: 'App',
  metaInfo() {
    return {
      title: this.$t('metaData.default.title'),
      titleTemplate: this.$t('metaData.default.template'),
      meta: [
        {
          property: 'description',
          content: this.$t('metaData.default.description'),
          vmid: 'description',
        },
        {
          property: 'og:title',
          content: this.$t('metaData.default.og.title'),
          vmid: 'og:title',
        },
        {
          property: 'og:description',
          content: this.$t('metaData.default.og.description'),
          vmid: 'og:description',
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:image',
          // eslint-disable-next-line
          content: `https://interim.taskforce.net${require('@/assets/graphics/registration.jpg')}`,
        },
        {
          property: 'og:url',
          content: `https://interim.taskforce.net${this.$route.fullPath}`,
        },
        {
          property: 'twitter:card',
          content: 'summary',
        },
        {
          property: 'twitter:site',
          content: this.$t('metaData.default.twitter.title'),
        },
      ],
    };
  },
};
</script>
