import httpClient from './httpClient';

const END_POINT = '/users';
const mapAuthHeader = (token, opts = {}) => ({
  headers: {
    Authorization: `Bearer ${token}`,
    ...opts,
  },
});

const getUser = (id, token) => httpClient.get(`${END_POINT}/${id}`, {
  ...mapAuthHeader(token),
});

const getUserAsset = (id, assetType, token) => httpClient.get(`${END_POINT}/${id}/${assetType}`, {
  ...mapAuthHeader(token),
  responseType: 'blob',
});

const updateUser = (id, user, token) => httpClient.put(`${END_POINT}/${id}`, user, {
  ...mapAuthHeader(token),
});

const updateUserAsset = (id, assetType, file, token) => httpClient.post(`${END_POINT}/${id}/${assetType}`, file, {
  ...mapAuthHeader(token, {
    'Content-Type': 'multipart/form-data',
  }),
});

const selfAssessUser = (id, payload, token) => httpClient.patch(`${END_POINT}/${id}/self-assessment`, payload, {
  ...mapAuthHeader(token),
});

const updateSkills = (id, skills, token) => httpClient.put(`${END_POINT}/${id}/skills`, { skills }, {
  ...mapAuthHeader(token),
});

const updateWillingnessToTravel = (uuid, willingnessToTravel, locations, travelingInternational, token) => httpClient.put(`${END_POINT}/${uuid}/willingness-to-travel`, { willingnessToTravel, locations, travelingInternational }, {
  ...mapAuthHeader(token),
});

const updateAvailability = (
  id,
  available,
  perWeek,
  reason,
  otherDate,
  token,
) => httpClient.put(
  `${END_POINT}/${id}/availability`,
  {
    available,
    perWeek,
    reason,
    otherDate,
  },
  {
    ...mapAuthHeader(token),
  },
);

const updateLanguageSkills = (id, languageSkills, token) => httpClient.put(`${END_POINT}/${id}/language-skills`, { languageSkills }, {
  ...mapAuthHeader(token),
});

const cvEntryAdd = (uuid, entry, token) => httpClient.post(`${END_POINT}/${uuid}/cv-entry`, { entry }, {
  ...mapAuthHeader(token),
});

const cvEntryUpdate = (uuid, entry, token) => httpClient.put(`${END_POINT}/${uuid}/cv-entry/${entry.id}`, { entry }, {
  ...mapAuthHeader(token),
});

const cvEntryDelete = (uuid, id, token) => httpClient.delete(`${END_POINT}/${uuid}/cv-entry/${id}`, {
  ...mapAuthHeader(token),
});

const educationEntryAdd = (uuid, entry, token) => httpClient.post(`${END_POINT}/${uuid}/education-entry`, { entry }, {
  ...mapAuthHeader(token),
});

const educationEntryUpdate = (uuid, entry, token) => httpClient.put(`${END_POINT}/${uuid}/education-entry/${entry.id}`, { entry }, {
  ...mapAuthHeader(token),
});

const educationEntryDelete = (uuid, id, token) => httpClient.delete(`${END_POINT}/${uuid}/education-entry/${id}`, {
  ...mapAuthHeader(token),
});

const subscribeMarketingMails = (uuid, subscribe, token) => httpClient.post(`${END_POINT}/${uuid}/subscribe`, { subscribe }, {
  ...mapAuthHeader(token),
});

// Documents

const getDocuments = (uuid, token) => httpClient.get(`${END_POINT}/${uuid}/documents`, {
  ...mapAuthHeader(token),
});

const getDocument = (uuid, filename, token, download) => {
  let url = `${END_POINT}/${uuid}/documents/${filename}`;

  if (download === true) {
    url += '/download';
  }

  return httpClient.get(url, {
    ...mapAuthHeader(token),
    responseType: 'blob',
  });
};

const uploadDocument = (uuid, document, token) => httpClient.post(
  `${END_POINT}/${uuid}/documents`,
  document,
  {
    ...mapAuthHeader(token, {
      'Content-Type': 'multipart/form-data',
    }),
  },
);

const deleteDocument = (uuid, filename, token) => httpClient.delete(`${END_POINT}/${uuid}/documents/${filename}`, {
  ...mapAuthHeader(token),
});

export {
  getUser,
  getUserAsset,
  updateUser,
  updateUserAsset,
  selfAssessUser,
  updateSkills,
  updateWillingnessToTravel,
  updateAvailability,
  updateLanguageSkills,
  cvEntryAdd,
  cvEntryUpdate,
  cvEntryDelete,
  educationEntryAdd,
  educationEntryUpdate,
  educationEntryDelete,
  subscribeMarketingMails,
  getDocuments,
  getDocument,
  uploadDocument,
  deleteDocument,
};
