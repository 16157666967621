import Vue from 'vue';
import VueSlideBar from 'vue-slide-bar';
import VueMatchMedia from '@webqam/vue-match-media';
import VueMeta from 'vue-meta';
import EventBus from './event-bus';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';

const breakpoints = {
  lg: 1800,
  md: 1200,
  sm: 900,
  xs: 600,
  page: 1248,
};

const mediaQueries = {
  xl: {
    minWidth: breakpoints.lg + 1,
  },
  lg: {
    maxWidth: breakpoints.lg,
  },
  md: {
    maxWidth: breakpoints.md,
  },
  sm: {
    maxWidth: breakpoints.sm,
  },
  xs: {
    maxWidth: breakpoints.xs,
  },
  pageMaxSize: {
    maxWidth: breakpoints.page,
  },
  onlyLg: {
    minWidth: breakpoints.md + 1,
    maxWidth: breakpoints.lg,
  },
  onlyMd: {
    minWidth: breakpoints.sm + 1,
    maxWidth: breakpoints.md,
  },
  onlySm: {
    minWidth: breakpoints.xs + 1,
    maxWidth: breakpoints.sm,
  },
  mdAndUp: {
    minWidth: breakpoints.sm + 1,
  },
  smAndUp: {
    minWidth: breakpoints.xs + 1,
  },
  xsAndUp: {
    minWidth: 0,
  },
};

Vue.component('VueSlideBar', VueSlideBar);

Vue.config.productionTip = false;
Vue.use(VueMatchMedia, { breakpoints: mediaQueries });
Vue.use(VueMeta);
Vue.use(EventBus);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
