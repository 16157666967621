import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';
import jwtDecode from 'jwt-decode';
import {
  getUser,
  getUserAsset,
  updateUser,
  selfAssessUser,
} from '@/api/users.api';
import {
  listSkills,
} from '@/api/skills.api';
import { listUsers } from '@/api/admins.api';
import { refresh } from '@/api/auth.api';
import { permissions } from '@/values';
import i18n from '@/i18n';

Vue.use(Vuex);

const blobToBase64 = (blob) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

const vuexPersist = new VuexPersist({
  key: 'cocoonStorage',
  storage: window.localStorage,
  reducer: (state) => ({
    uuid: state.uuid,
    refreshToken: state.refreshToken,
    comments: state.comments,
  }),
});

const comments = {
  namespaced: true,
  state: {
    filterByType: [],
  },
  getters: {
    filterByType(state) {
      return state.filterByType;
    },
  },
  mutations: {
    resetFilter(state) {
      state.filterByType = [];
    },
    toggleFilter(state, type) {
      if (!state.filterByType.includes(type)) {
        state.filterByType.push(type);
        return;
      }

      state.filterByType = state.filterByType.filter((filter) => filter !== type);
    },
  },
};

export default new Vuex.Store({
  modules: {
    comments,
  },
  state: {
    accessToken: {
      token: '',
      expires: '',
    },
    refreshToken: '',
    uuid: '',
    usermeta: {},
    user: {},
    role: '',
    status: '',
    group: '',
    avatar: '',
    availableSkills: [],
  },
  mutations: {
    setAccessToken(state, { accessToken, expires }) {
      state.accessToken.token = accessToken;
      state.accessToken.expires = expires;
    },

    setRefreshToken(state, token) {
      state.refreshToken = token;
    },

    setUuid(state, payload) {
      state.uuid = payload;
    },

    setRole(state, payload) {
      state.role = payload;
    },

    setUser(state, {
      User, status, group, ...roleData
    }) {
      if (state.uuid === roleData.uuid && state.role === User.role) {
        state.user = roleData;
        state.usermeta = User;
        state.status = status;
        state.group = group;
      }
    },

    setAvatar(state, payload) {
      const file = new Blob([payload], { type: 'image/jpg' });
      blobToBase64(file).then((imgBase64) => {
        state.avatar = imgBase64;
      });
    },

    updateUser(state, payload) {
      state.user = { ...state.user, ...payload };
    },

    setAdminList(state, payload) {
      state.adminList = payload.map(({ uuid, group, User }) => ({ uuid, group, ...User }));
    },

    setAvailableSkills(state, payload) {
      state.availableSkills = payload.data;
    },

    resetStore(state) {
      state.accessToken = {
        token: '',
        expires: '',
      };
      state.refreshToken = '';
      state.uuid = '';
      state.usermeta = {};
      state.user = {};
      state.role = '';
      state.status = '';
      state.group = '';
      state.avatar = '';
      state.availableSkills = [];
    },
  },
  getters: {
    loggedIn(state) {
      return (
        state.accessToken.token
        && (state.accessToken.expires - (Date.now() / 1000) > 30)
      );
    },

    fullName(state) {
      return `${state.usermeta.firstname} ${state.usermeta.lastname}`;
    },

    userCan: (state) => (action, self) => {
      if (action === 'edit') {
        return state.uuid === self || state.group.includes('superadmin') || state.group.includes('delivery-admin');
      }

      if (Array.isArray(state.group)) {
        return state.group.some((group) => permissions[action].includes(group));
      }

      return false;
    },

    availableSkills(state) {
      return state.availableSkills;
    },

    isAdmin(state) {
      return state.role === 'admin';
    },
  },
  actions: {
    isLoggedIn({ state, dispatch }) {
      return new Promise((resolve, reject) => {
        if (state.accessToken.token && (state.accessToken.expires - (Date.now() / 1000) > 30)) {
          resolve(state.accessToken.token, false);
        } else {
          dispatch('tryRefresh')
            .then(() => {
              resolve(state.accessToken.token, true);
            })
            .catch(() => {
              dispatch('logout')
                .then(() => {
                  // eslint-disable-next-line
                  this._vm.$eventBus.$emit('notificate', { status: 401, message: i18n.t('notifications.session.expired') });
                  reject();
                });
            });
        }
      });
    },

    tryRefresh({ state, dispatch }) {
      return new Promise((resolve, reject) => {
        if (state.refreshToken) {
          refresh(state.refreshToken)
            .then((request) => request.data)
            .then(({ accessToken }) => {
              dispatch('verifyAccessToken', accessToken)
                .then(() => resolve())
                .catch(() => reject());
            })
            .catch((error) => {
              console.error(error);
              reject();
            });
        } else {
          reject();
        }
      });
    },

    handleLogin({ commit, dispatch }, { accessToken, refreshToken }) {
      return new Promise((resolve, reject) => {
        dispatch('verifyAccessToken', accessToken)
          .then(() => {
            commit('setRefreshToken', refreshToken);
            dispatch('fetchUserData')
              .then(() => {
                resolve();
              });
          })
          .catch((error) => {
            console.error(error);
            reject();
          });
      });
    },

    verifyAccessToken({ commit, dispatch }, accessToken) {
      return new Promise((resolve, reject) => {
        const body = jwtDecode(accessToken);

        if (!body.uuid) reject();

        commit('setAccessToken', { accessToken, expires: body.exp });
        commit('setUuid', body.uuid);
        commit('setRole', body.role);
        dispatch('fetchUserData')
          .then(() => resolve());
      });
    },

    logout({ commit }) {
      return new Promise((resolve) => {
        commit('resetStore');
        resolve();
      });
    },

    fetchUserData({ state, commit }) {
      return new Promise((resolve, reject) => {
        getUser(state.uuid, state.accessToken.token)
          .then((result) => result.data)
          .then((data) => {
            commit('setUser', data.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    fetchAvatar({ state, commit, dispatch }, force = false) {
      return new Promise((resolve, reject) => {
        if (state.avatar && !force) {
          resolve(state.avatar);
        } else {
          dispatch('isLoggedIn')
            .then(() => {
              getUserAsset(state.uuid, 'avatar', state.accessToken.token)
                .then((result) => {
                  commit('setAvatar', result.data);
                  resolve(state.avatar);
                })
                .catch((error) => reject(error));
            });
        }
      });
    },

    /**
     * This method is currently not used in the codebase. Consider partially updating the user instead,
     * as the full user object payload might grow quite large and hence send a lot of unnecessary data.
     * The server has limits in place that might prevent you from uploading your payload here, too.
     */
    uploadUser({ state, dispatch }) {
      return new Promise((resolve, reject) => {
        dispatch('isLoggedIn')
          .then(() => {
            updateUser(state.uuid, state.user, state.accessToken.token)
              .then(() => {
                resolve();
              })
              .catch((err) => {
                console.error(err);
                reject(err);
              });
          });
      });
    },

    selfAssessUser({ state, dispatch }) {
      return new Promise((resolve, reject) => {
        dispatch('isLoggedIn')
          .then(() => {
            selfAssessUser(state.uuid, JSON.stringify({
              experiences: state.user.experiences,
              industries: state.user.industries,
              main_operationals: state.user.main_operationals,
              main_industries: state.user.main_industries,
            }), state.accessToken.token)
              .then((res) => {
                dispatch('fetchUserData');
                resolve(res.data);
              })
              .catch((error) => {
                reject(error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getAdminList({ state, dispatch }) {
      return new Promise((resolve, reject) => {
        if (state.adminList) resolve(state.adminList);
        else {
          dispatch('fetchAdminList')
            .then(() => {
              resolve(state.adminList);
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },

    fetchAdminList({ state, dispatch, commit }) {
      return new Promise((resolve, reject) => {
        dispatch('isLoggedIn')
          .then((token) => {
            if (state.role === 'admin') {
              listUsers({
                role: 'admin',
                scope: 'list',
              }, token)
                .then((r) => r.data)
                .then(({ data }) => {
                  commit('setAdminList', data.rows);
                  resolve();
                })
                .catch((error) => {
                  reject(error);
                });
            }
          });
      });
    },

    fetchAvailableSkills({ state, commit }) {
      return new Promise((resolve, reject) => {
        listSkills(state.accessToken.token)
          .then((result) => result.data)
          .then((data) => {
            commit('setAvailableSkills', data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  plugins: [vuexPersist.plugin],
});
