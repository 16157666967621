import Vue from 'vue';
import VueRouter from 'vue-router';
import qs from 'qs';
import store from '@/store';
import i18n from '@/i18n';

Vue.use(VueRouter);

const browserLang = navigator.language.substring(0, 2) || navigator.userLanguage.substring(0, 2);
const langs = ['en', 'de'];

const routes = [
  {
    path: '/:lang(en|de)?',
    component: {
      name: 'Language',
      template: '<router-view />',
    },
    children: [
      // {
      //   path: 'maintenance',
      //   name: 'Maintenance',
      //   component: () => import('../views/MaintenanceMode.vue'),
      // },
      // {
      //   path: '*',
      //   redirect: (to) => ({ name: 'Maintenance', params: to.params }),
      // },
      {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */'../views/Home.vue'),
        meta: {
          requiresAuth: true,
        },
        redirect: (to) => {
          if (store.getters.loggedIn) {
            if (store.state.status === 'self-assessment') {
              return { name: 'Application', params: to.params };
            }

            if (store.state.role === 'admin') {
              return { name: 'Applications', params: to.params };
            }

            return { name: 'Profile', params: to.params };
          }

          return { name: 'Login', params: to.params };
        },
        children: [
          {
            path: 'users',
            name: 'Users',
            component: () => import(/* webpackChunkName: "admin" */'../views/Admin/Users.vue'),
            props: (route) => ({ ...route.query }),
            meta: {
              requiresType: {
                role: 'admin',
              },
            },
          },
          {
            path: 'users/:username',
            name: 'UserDetail',
            component: () => import(/* webpackChunkName: "admin" */'../views/Admin/UserDetail.vue'),
            props: ({ params: { username } }) => ({ username }),
            meta: {
              requiresType: {
                role: 'admin',
              },
              redirectParent: 'Users',
            },
          },
          {
            path: 'profile',
            name: 'Profile',
            component: () => import(/* webpackChunkName: "user" */ '../views/User/Profile.vue'),
            props: true,
          },
          {
            path: 'application',
            name: 'Application',
            component: () => import(/* webpackChunkName: "user" */ '../views/User/Application.vue'),
            meta: {
              hideForType: {
                role: 'admin',
                group: 'expert',
              },
            },
          },
          {
            path: 'applications',
            name: 'Applications',
            component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/Applications.vue'),
            meta: {
              requiresType: {
                role: 'admin',
              },
            },
          },
          {
            path: 'applications/:username',
            name: 'ApplicationDetail',
            component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/ApplicationDetail.vue'),
            props: true,
            meta: {
              requiresType: {
                role: 'admin',
              },
              redirectParent: 'Applications',
            },
          },
          {
            path: 'projects',
            name: 'Projects',
            component: () => import(/* webpackChunkName: "projects" */ '../views/Functional/Projects.vue'),
            meta: {
              hideForType: {
                status: 'self-assessment',
              },
            },
          },
          {
            path: 'projects/:id',
            name: 'ProjectDetail',
            component: () => import(/* webpackChunkName: "projects" */ '../views/Functional/ProjectDetail.vue'),
            props: ({ params: { id, ...others } }) => ({ id: Number.parseInt(id, 10), ...others }),
            meta: {
              hideForType: {
                status: 'self-assessment',
              },
            },
          },
          {
            path: 'messages/:projectId?/:applicationId?',
            name: 'ProjectApplications',
            component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/ProjectApplications.vue'),
            props: ({ params: { projectId, applicationId, ...others } }) => ({
              projectId: Number.parseInt(projectId, 10),
              applicationId: Number.parseInt(applicationId, 10),
              ...others,
            }),
            meta: {
              hideForType: {
                status: 'self-assessment',
              },
            },
          },
          {
            path: 'deals',
            name: 'Leads',
            component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/Leads.vue'),
            meta: {
              requiresType: {
                role: 'admin',
              },
            },
          },
          {
            path: 'deals/:id',
            name: 'LeadDetail',
            component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/LeadDetail.vue'),
            meta: {
              requiresType: {
                role: 'admin',
              },
            },
          },
          {
            path: 'admins',
            name: 'Admins',
            component: () => import(/* webpackChunkName: "admin" */'../views/Admin/Admins.vue'),
            props: (route) => ({ ...route.query }),
            meta: {
              requiresType: {
                group: 'superadmin',
              },
            },
          },
        ],
      },
      {
        path: 'public',
        name: 'Public',
        component: () => import(/* webpackChunkName: "publicViews" */ '../views/Public.vue'),
        children: [
          {
            path: 'projects',
            name: 'PublicProjects',
            component: () => import(/* webpackChunkName: "publicProjects" */ '../views/Public/Projects.vue'),
          },
          {
            path: 'projects/:id',
            name: 'PublicProjectDetail',
            component: () => import(/* webpackChunkName: "publicProjects" */ '../views/Public/ProjectDetail.vue'),
            props: (route) => ({ id: Number.parseInt(route.params.id, 10) }),
          },
        ],
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
        meta: {
          hideForAuth: true,
        },
      },
      {
        path: 'signup',
        name: 'Signup',
        component: () => import(/* webpackChunkName: "signup" */ '../views/Signup.vue'),
      },
      {
        path: 'signup/verify/:token',
        component: () => import(/* webpackChunkName: "signup" */ '../views/SignupVerify.vue'),
        props: true,
      },
      {
        path: 'request-password',
        name: 'RequestPassword',
        component: () => import(/* webpackChunkName: "recovery" */ '../views/RequestPassword.vue'),
      },
      {
        path: 'reset-password/:token?',
        name: 'ResetPassword',
        component: () => import(/* webpackChunkName: "recovery" */ '../views/ResetPassword.vue'),
        props: true,
      },
      {
        path: 'set-password/:email?',
        name: 'SetPassword',
        component: () => import(/* webpackChunkName: "recovery" */ '../views/SetPassword.vue'),
        props: (route) => ({
          email: route.params.email,
          formalTitle: route.query.formalTitle,
          lastname: route.query.lastname,
        }),
      },
      {
        path: 'accept-terms',
        name: 'AcceptTerms',
        component: () => import(/* webpackChunkName: "recovery" */ '../views/AcceptTerms.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      // add routes before this line
      // if no route above matches, show 404 not found page
      {
        path: '*',
        name: 'NotFound',
        component: () => import(/* webpackChunkname: "404notfound" */ '../views/404NotFound.vue'),
      },
    ],
  },
  {
    path: '/',
    redirect: `/${browserLang || 'en'}`,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: '-is-active',
  linkExactActiveClass: '-is-exact-active',
  scrollBehavior(to, from, savedPosition) {
    const saveRoutes = ['Applications', 'Users', 'Projects', 'PublicProjects', 'Leads'];

    if (savedPosition && saveRoutes.includes(to.name)) {
      return savedPosition;
    }

    return { x: 0, y: 0 };
  },
  parseQuery(query) {
    return qs.parse(query);
  },
  stringifyQuery(query) {
    const result = qs.stringify(query);
    return result ? (`?${result}`) : '';
  },
});

const userTypeHandler = (to, from, next) => {
  const recordRequired = to.matched.find((record) => record.meta.requiresType);
  if (recordRequired) {
    const types = Object.keys(recordRequired.meta.requiresType);
    if (types.find((type) => {
      if (Array.isArray(store.state[type])) {
        return store.state[type].includes(recordRequired.meta.requiresType[type]);
      }

      return recordRequired.meta.requiresType[type] === store.state[type];
    })) {
      next();
    } else {
      const { redirect } = to.query;
      if (redirect) {
        next({ path: redirect, params: to.params });
      } else {
        next({ name: 'Home', params: to.params });
      }
    }
  } else {
    next();
  }

  const recordHide = to.matched.find((record) => record.meta.hideForType);
  if (recordHide) {
    const types = Object.keys(recordHide.meta.hideForType);
    if (types.find((type) => recordHide.meta.hideForType[type] === store.state[type])) {
      next({ name: 'Home', params: to.params });
    } else {
      next();
    }
  } else {
    next();
  }
};

const handleLanguage = (to, from, next) => {
  const hasLang = to.params && to.params.lang && langs.includes(to.params.lang);
  const hadLang = from.params && from.params.lang;
  let lang = '';

  if (hasLang && hadLang && from.params.lang.toLowerCase() === to.params.lang.toLowerCase()) {
    return true;
  }

  if (hasLang) {
    lang = to.params.lang.toLowerCase();
  }

  if (!langs.includes(lang)) {
    if (langs.includes(browserLang)) {
      lang = browserLang;
    } else {
      lang = 'en';
    }
  }

  i18n.locale = lang;

  if (!hasLang) {
    return next(`/${lang}${to.fullPath}`);
  }

  return true;
};

const userConsentHandler = (to, from, next) => {
  if (
    to.name === 'AcceptTerms'
    && (store.state.role === 'admin' || (store.state.user.consentAgb === true && store.state.user.consentPrivacy === true))
  ) {
    // redirect direct call to route accept-terms if terms already accepted or role is admin
    next({ name: 'Home', params: to.params });
  } else if (
    store.state.role === 'manager'
    && (store.state.user.consentAgb !== true || store.state.user.consentPrivacy !== true)
    && to.name !== 'AcceptTerms'
  ) {
    next({
      name: 'AcceptTerms',
      params: to.params,
      query: {
        redirect: to.path,
      },
    });
  } else {
    next();
  }
};

router.beforeEach((to, from, next) => {
  if (handleLanguage(to, from, next)) {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      store.dispatch('isLoggedIn')
        .then(() => {
          userConsentHandler(to, from, next);
          userTypeHandler(to, from, next);
        })
        .catch(() => {
          next({
            name: 'Login',
            params: to.params,
            query: {
              redirect: to.path,
            },
          });
        });
    } else {
      next();
    }

    if (to.matched.some((record) => record.meta.hideForAuth)) {
      store.dispatch('isLoggedIn')
        .then(() => {
          const { redirect } = to.query;
          if (redirect) {
            next({ path: redirect, params: to.params });
          } else {
            next({ name: 'Home', params: to.params });
          }
        })
        .catch(() => {
          next();
        });
    } else {
      next();
    }
  } else {
    next();
  }
});

router.afterEach((to) => {
  if (typeof gtag === 'function') {
    // eslint-disable-next-line
    gtag('event', 'page_view', {
      page_title: to.meta.title,
      page_location: to.fullPath,
      page_path: to.path,
    });
  }
});

export default router;
