import httpClient from './httpClient';

const END_POINT = '/auth';

const signUp = (user) => httpClient.post(`${END_POINT}/signup`, user, {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

const login = (userCreds) => httpClient.post(`${END_POINT}/signin`, userCreds);

const refresh = (token) => httpClient.post(`${END_POINT}/refresh`, { token });

const verify = (token) => httpClient.get(`${END_POINT}/verify/${token}`);

export {
  signUp,
  login,
  refresh,
  verify,
};
