import httpClient from './httpClient';

const END_POINT = '/users';
const mapAuthHeader = (token, opts = {}) => ({
  headers: {
    Authorization: `Bearer ${token}`,
    ...opts,
  },
});

const listUsers = (filter, token) => httpClient.get(`${END_POINT}?${new URLSearchParams(filter).toString()}`, {
  ...mapAuthHeader(token),
});

const findUser = (username, token) => httpClient.get(`${END_POINT}/${username}`, {
  ...mapAuthHeader(token),
});

const createUser = (user, token) => httpClient.post(END_POINT, user, {
  ...mapAuthHeader(token),
});

const patchUser = (id, fields, token) => httpClient.patch(`${END_POINT}/${id}`, fields, {
  ...mapAuthHeader(token),
});

const removeUser = (id, token) => httpClient.delete(`${END_POINT}/${id}`, {
  ...mapAuthHeader(token),
});

const checkupApproveUser = (id, token) => httpClient.patch(`${END_POINT}/${id}/approveCheckup`, {}, {
  ...mapAuthHeader(token),
});

const selectionVoteUser = (id, payload, token) => httpClient.post(`${END_POINT}/${id}/voteSelection`, payload, {
  ...mapAuthHeader(token),
});

const setAssignee = (id, payload, token) => httpClient.post(`${END_POINT}/${id}/setAssignee`, payload, {
  ...mapAuthHeader(token),
});

const saveInterview = (id, payload, token) => httpClient.patch(`${END_POINT}/${id}/saveInterview`, payload, {
  ...mapAuthHeader(token),
});

const completeInterview = (id, payload, token) => httpClient.patch(`${END_POINT}/${id}/completeInterview`, payload, {
  ...mapAuthHeader(token),
});

const saveCategorization = (id, payload, token) => httpClient.post(`${END_POINT}/${id}/saveCategorization`, payload, {
  ...mapAuthHeader(token),
});

const downloadPdfVita = (id, payload, token, language = 'de') => httpClient.post(`${END_POINT}/${id}/vitaAsPdf?lang=${language}`, payload, {
  ...mapAuthHeader(token),
  responseType: 'blob',
});

const getComments = (id, token, commentableType) => {
  if (commentableType) {
    return httpClient.get(`${END_POINT}/${id}/comments/${commentableType}`, {
      ...mapAuthHeader(token),
    });
  }

  return httpClient.get(`${END_POINT}/${id}/comments`, {
    ...mapAuthHeader(token),
  });
};

const saveComment = (id, payload, token) => httpClient.post(`${END_POINT}/${id}/comments`, payload, {
  ...mapAuthHeader(token),
});

const deleteComment = (managerId, commentId, token) => httpClient.delete(`${END_POINT}/${managerId}/comments/${commentId}`, {
  ...mapAuthHeader(token),
});

const listMatches = (managerUuid, filter, token) => httpClient.get(`${END_POINT}/${managerUuid}/matches?${new URLSearchParams(filter).toString()}`, {
  ...mapAuthHeader(token),
});

export {
  listUsers,
  findUser,
  createUser,
  patchUser,
  removeUser,
  checkupApproveUser,
  selectionVoteUser,
  setAssignee,
  saveInterview,
  completeInterview,
  saveCategorization,
  downloadPdfVita,
  getComments,
  saveComment,
  deleteComment,
  listMatches,
};
