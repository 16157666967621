export const applicationStates = ['self-assessment', ['checkup', 'selection'], 'sighting', 'consolidation', 'decision', 'completed', 'partner'];

export const managerGroups = ['pending', 'expert', 'executive'];

export const evaluationFields = ['style', 'atmosphere', 'professionalFit', 'expertise', 'personalFit', 'businessFit', 'sales', 'comprehension'];

export const adminGroups = ['superadmin', 'executive-mgmt', 'management', 'delivery', 'delivery-admin', 'applicant-mgmt'];

export const permissions = {
  updateUserMeta: ['superadmin', 'applicant-mgmt', 'delivery', 'delivery-admin'],
  updateUserVita: ['superadmin', 'applicant-mgmt', 'delivery-admin'],
  updateUserAvailability: ['superadmin', 'executive-mgmt', 'management', 'delivery', 'delivery-admin', 'applicant-mgmt'],
  updateManagerCv: ['superadmin', 'applicant-mgmt', 'delivery-admin'],
  updateUserCategories: ['superadmin', 'applicant-mgmt', 'delivery-admin'],
  updateManagerSkills: ['superadmin', 'applicant-mgmt', 'delivery', 'delivery-admin'],
  updateManagerLocations: ['superadmin', 'applicant-mgmt', 'delivery', 'delivery-admin'],
  updateManagerProfileInfo: ['superadmin', 'executive-mgmt', 'management', 'delivery', 'delivery-admin', 'applicant-mgmt'],
  updateDailyRate: ['superadmin', 'executive-mgmt', 'management', 'delivery', 'delivery-admin', 'applicant-mgmt'],
  updateLanguageSkills: ['superadmin', 'executive-mgmt', 'management', 'delivery', 'delivery-admin', 'applicant-mgmt'],
  approveCheckup: ['superadmin', 'applicant-mgmt'],
  voteSelection: ['management', 'executive-mgmt'],
  setAssignee: ['superadmin', 'applicant-mgmt', 'delivery'],
  deleteUser: ['superadmin', 'delivery-admin'],
  deactivateUser: ['superadmin', 'delivery-admin'],
  setGroup: ['superadmin', 'delivery-admin'],
  setAdminStatus: ['superadmin', 'delivery-admin'],
  setOwner: ['superadmin', 'applicant-mgmt', 'delivery-admin'],
  publishProject: ['superadmin', 'delivery-admin', 'delivery'],
  createMatch: ['superadmin', 'executive-mgmt', 'management', 'delivery', 'delivery-admin'],
  sendProjectCampaign: ['superadmin', 'applicant-mgmt', 'delivery', 'delivery-admin'],
  deleteComment: ['superadmin', 'delivery-admin'],
  manageAdmins: ['superadmin'],
  createAdmins: ['superadmin'],
  editAdmins: ['superadmin'],
  deactivateAdmins: ['superadmin'],
  deleteAdmins: ['superadmin'],
};

export const possibleAssignees = {
  sighting: ['delivery', 'delivery-admin'],
  consolidation: ['delivery-admin', 'management'],
  decision: ['executive-mgmt', 'management'],
  projects: ['delivery', 'delivery-admin', 'management'],
  managers: ['delivery', 'delivery-admin', 'management', 'applicant-mgmt'],
  leads: ['delivery', 'delivery-admin', 'management'],
};
